import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/auth.service";
import { MixpanelService } from "../../shared/service/mixpanel.service";
import { AgencyService } from "../agency/agency.service";
import { LinkedinProfileComponent } from "../app-linkedin-profile/app-linkedin-profile.component";
import { BillingService } from "../billing/billing.service";
import { CommonService } from "../common.service";

declare let pendo: any;
declare let analytics: any;

@Component({
  selector: "app-step-plan",
  templateUrl: "planselection.component.html",
  styleUrls: ["planselection.component.css"],
})
export class PlanSelectionComponent implements OnInit {
  //New variables
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  stepOneCompleted: boolean = false;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  button_spinner = false;
  btnTitle = "View agency plans";
  agencyPlan = false;
  token;
  upgrade_plan: boolean = false;
  btn1;
  btn2;
  btn3;
  btn4;
  cardAdded = false;
  billingdetailsadded = false;
  showCancelButton = true;
  isNew;
  subcriptiontype;
  email;
  id;
  name;
  uuid;
  //  billing details
  addorUpdate: boolean = false;
  fname;
  lname;
  editDisable: boolean;
  paymentDetailsUrl;
  navigateToUrl = true;
  cardAddeddetails = false;
  allCountryList = [];
  // email;
  // id;
  // name;
  // uuid;
  sub_type;
  showorhideModal;
  showorhideProfileModal;
  freeBasicPlan;
  heading = "Select a plan";
  discription =
    "Select a plan and start your 15-day free trial. No credit card is required!";
  linkedinConnectionData;
  linkedinStatus;
  plantype;
  headerHeading;
  showpopMsg = false;
  showverificationpopMsg = false;
  // userLinkdinflag = true;

  // yearlyPlan variable;

  yearlyPlan = false;
  referral_used;
  linkedinId;
  billingId;
  companyId;
  previous_plan;
  actor_urn;
  update_bill_email = false;

  //  Billing details

  billingForm = new FormGroup({
    // f_name: new FormControl("", Validators.required),
    // l_name: new FormControl("", Validators.required),
    line1: new FormControl("", Validators.required),
    line2: new FormControl(""),
    city: new FormControl("", Validators.required),
    state: new FormControl("", Validators.required),
    country: new FormControl("", Validators.required),
    postal_code: new FormControl("", Validators.required),
  });
  linkedInForm = new FormGroup({
    userid: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });
  linkedInotpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

  @ViewChild(LinkedinProfileComponent)
  linkedInProfileComponent: LinkedinProfileComponent;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private billing: BillingService,
    private spinner: NgxSpinnerService,
    private common: CommonService,
    private mixPanel: MixpanelService,
    private auth: AuthService,
    private agency: AgencyService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    // this.getLinkdeinCredList();
    this.referral_used = localStorage.getItem("referral_used");
    if (this.referral_used == "TRIGGIFY_DEAL") {
      this.yearlyPlan = true;
    }
    this.freeBasicPlan = localStorage.getItem("first_step");
    this.headerHeading = localStorage.getItem("headerHeading");
    if (this.freeBasicPlan == "true") {
      this.stepOne = false;
      this.stepTwo = true;
      this.stepOneCompleted = true;
      this.heading = "Location details";
      this.discription =
        "This location must be the same as the location from where you normally access the LinkedIn account. We will provide you with a free proxy to protect your IP address";
    }
    this.sub_type = localStorage.getItem("sub_type");
    this.getcountryList();
    // this.currentPlan();
    // this.cardAddedres();
    this.getCurrentPlan();
    // this.billingDetails();
    // this.linkdinProfiledelete()

    // this.getLinkedinProfile();
    // for (let index = 0; index < this.countryList.length; index++) {
    //   this.allCountryList.push({ name: this.countryList[index] });
    // }
    // console.log(this.allCountryList, "all country");
    this.email = localStorage.getItem("email");
    this.id = localStorage.getItem("id");
    this.name = localStorage.getItem("name");
    this.uuid = localStorage.getItem("uuid");

    window.scrollTo(0, 0);
    // Get current Plan

    this.token = localStorage.getItem("token");
    // console.log(this.token,"token");
    if (this.token == null) {
      this.toastr.error("Session expire! please login and continue", "", {
        closeButton: true,
        positionClass: "toast-bottom-center",
      });
      this.router.navigate([""]);
      this.spinner.hide();
    }
    // this.getCardaddedOrNot();
    this.spinner.hide();
  }

  getLinkdeinCredList() {
    this.agency.llinkedincredList().subscribe((res) => {
      if (res.body.length >= 1) {
        for (let i = 1; i < res.body.length; i++) {
          this.agency.deleteLinkedin(res.body[i].id).subscribe((res) => {});
        }
        this.linkedinId = res.body[0].id;
        this.update_bill_email = true;
      } else {
        this.update_bill_email = false;
      }
    });
  }

  showpopup(data1, data2) {
    this.showorhideModal = true;
  }
  showLinpopup(data1, data2) {
    this.showorhideModal = true;
  }

  hidepopup() {
    this.showorhideModal = false;
  }
  hideLinpopup() {
    this.showorhideModal = false;
  }


  getCurrentPlan() {
    this.spinner.show();
    this.billing.currentPlan().subscribe((response) => {
      // console.log(response, "Current Plan");
      this.spinner.hide();
      if (response.body.message.description == "Free Basic Plan") {
        this.navigateToUrl = false;
        this.stepOne = false;
        this.stepTwo = true;
        this.stepOneCompleted = true;
      } else {
        this.navigateToUrl = true;
      }
      this.subcriptiontype = response.body.message;
      this.isNew = response.body.is_new;
      if (response.body.message.is_change == true) {
        this.billing.changePlan().subscribe((res) => {
          // console.log(res,"change plan response");
          this.toastr.success(res.body.message, "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });

          setTimeout(() => {
            this.spinner.hide();
          }, 2000);
        });
      } else {
        this.spinner.hide();
      }

      if (response.body.message == "No subscription found") {
        this.previous_plan = response.body.previous_plan;
        this.btn1 = "Select";
        this.btn2 = "Select";
        this.btn3 = "Select";
        this.btn4 = "Select";
        // this.spinner.hide()
        this.showCancelButton = false;
        localStorage.setItem("susbcribe", "False");
        localStorage.setItem("headerHeading", "notfree");
      } else if (
        response.body.message.description == null ||
        response.body.message.description == "null"
      ) {
        this.previous_plan = response.body.message.previous_plan;
      }
    });
  }

  // subscribe Plan

  subscribePlan(sub_type, amount, plan_type) {
    if (this.previous_plan.length > 0) {
      if (this.previous_plan.includes("Agency")) {
        if (sub_type.includes("Agency")) {
        } else {
          this.getLinkdeinCredList();
        }
      } else {
        if (sub_type.includes("Agency")) {
        } else {
          this.agency.llinkedincredList().subscribe((res) => {
            if (res.body.length > 0) {
              this.linkedinId = res.body[0].id;
              this.update_bill_email = true;
            } else {
              this.update_bill_email = false;
            }
          });
          // this.getLinkdeinCredList();
        }
      }
    } else {
      if (sub_type.includes("Agency")) {
      } else {
        // this.getLinkdeinCredList();
        this.agency.llinkedincredList().subscribe((res) => {
          if (res.body.length > 0) {
            this.linkedinId = res.body[0].id;
            this.update_bill_email = true;
          } else {
            this.update_bill_email = false;
          }
        });
      }
    }
    localStorage.setItem("sub_type", sub_type);
    localStorage.setItem("plan_type", plan_type);
    localStorage.setItem("amount", amount);
    if (this.yearlyPlan) {
      localStorage.setItem("plan_interval", "year");
    } else {
      localStorage.setItem("plan_interval", "month");
    }
    if (this.agencyPlan == false) {
      this.sub_type = sub_type;
      this.plantype = plan_type;
      this.stepOne = false;
      this.stepTwo = true;
      this.stepOneCompleted = true;
      this.heading = "Location details";
      this.discription = `This location must be the same as the location from where you normally access the LinkedIn account. We will provide you with a free proxy to protect your IP address`;
      analytics.track("Selected a plan in the onboarding flow", {
        " plan type": sub_type,
      });
      localStorage.setItem("agencyPlan", "false");
    } else {
      localStorage.setItem("agencyPlan", "true");
      this.planCreate();
    }
  }


  getcountryList() {
    this.common.getAllCountry().subscribe((res) => {
      //   console.log(res, "countrylist");
      if (res.status == 200) {
        this.allCountryList = res.body.message.country_code;
      }
    });
  }

  onSubmit() {
    this.button_spinner = true;
    // console.log(this.billingForm.value, "billing Form");
    if (this.update_bill_email) {
      this.common
        .onUpdateBill(this.billingForm.value, this.linkedinId)
        .subscribe((res) => {
          this.button_spinner = false;
          if (res.status == 200) {
            this.toastr.success("Added successfully.", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            this.billingId = res.body.id;
            this.agency
              .updatecompanyresponse({ email: this.email }, this.linkedinId)
              .subscribe((res) => {
                // console.log(res, "company add response");
                this.companyId = res.body.id;
              });
            analytics.track("Entered location details in the onboarding flow", {
              country: this.billingForm.value.country,
            });

            // console.log(this.linkedinStatus, "status");
            if (false) {
            } else {
              this.stepOne = false;
              this.stepTwo = false;
              this.stepThree = true;
              this.stepOneCompleted = true;
              this.stepTwoCompleted = true;
              this.heading = "Connect LinkedIn";
              this.discription =
                "Connect your LinkedIn account by entering your credentials.";
            }
          } else {
            this.toastr.error(res.error.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          }
        });
    } else {
      this.common.onCreateBill(this.billingForm.value).subscribe((res) => {
        // console.log(res, "biiling added res");
        this.button_spinner = false;
        if (res.status == 200) {
          this.toastr.success("Added successfully.", "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
          this.billingId = res.body.id;
          this.agency.createCompany({ email: this.email }).subscribe((res) => {
            if (res.status == 200) {
              this.companyId = res.body.message.id;
            } else {
              this.toastr.error(res.error.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            }
          });
          analytics.track("Entered location details in the onboarding flow", {
            country: this.billingForm.value.country,
          });

          // console.log(this.linkedinStatus, "status");
          if (false) {
          } else {
            this.stepOne = false;
            this.stepTwo = false;
            this.stepThree = true;
            this.stepOneCompleted = true;
            this.stepTwoCompleted = true;
            this.heading = "Connect LinkedIn";
            this.discription =
              "Connect your LinkedIn account by entering your credentials.";
          }
        } else {
          this.toastr.error(res.error.message, "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
        }
      });
    }
  }

  billingDetails() {
    this.common.getBillingDetails(this.linkedinId).subscribe((res) => {
      // console.log(res, "biiling details resonse");
      this.spinner.hide();

      if (res.status == 200) {
        // let array = res.body.message.billing_details.name.split(" ");
        // console.log(array, "array");
        this.billing.deleteBillingDetail().subscribe((res) => {
          // console.log(res, "delete billing detail");
          this.billingdetailsadded = false;
        });
        // this.billingdetailsadded = true;
      } else {
        this.addorUpdate = false;
        this.billingdetailsadded = false;
      }
    });
  }


  // connect to linkedin

  // linkdin connect step

  connectToLinkedin() {
    this.showpopMsg = true;
    this.button_spinner = true;
    let obj = {
      billing_id: this.billingId,
      response_email_id: this.companyId,
    };
    let bodyData = { ...obj, ...this.linkedInForm.value };
    if (this.linkedinId == null) {
      this.common.onConnectToLinkedin(bodyData).subscribe((res) => {
        this.linkedinConnectionData = res.body;

        // this.linkedinId = res.body.linkedin
        this.button_spinner = false;
        if (res.status == 200) {
          this.linkedinId = res.body.linkedin;
          localStorage.setItem("linkedinId", res.body.linkedin);
          if (res.body.message == "added") {
            this.toastr.success(res.body.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            this.linkedinId = res.body.linkedin;
            localStorage.setItem("linkedinId", res.body.linkedin);
            // analytics.track("Connected LinkedIn in onboarding flow", {});
            // this.mixPanel.track("Connected LinkedIn successfully", {});
            this.linkedInProfileComponent.getLinkedinProfile(res.body.linkedin);
            this.showpopMsg = false;
          } else if (
            res.body.message ==
            "Incorrect Username or Password, Please check it"
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "We were not able to connect your account. Please double-check your credentials and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes(
              "Failed to refresh IP or reached max retry limit"
            )
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes(
              "phone number is required, please add phone number on your account"
            )
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "Please add a phone number to your LinkedIn account and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (res.body.message.includes("Your account is restricted")) {
            this.showpopMsg = false;
            this.toastr.error(
              "Your account is restricted. Please activate your LinkedIn account and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else if (
            res.body.message.includes("failed to generate captcha token")
          ) {
            this.showpopMsg = false;
            this.toastr.error(
              "Oops! We couldn't generate the captcha token. Please try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
            this.spinner.hide();
            this.showorhideModal = false;
          } else {
            this.showpopMsg = false;
            this.toastr.success(res.body.message, "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
            this.spinner.hide();
            this.showorhideModal = true;
            localStorage.setItem("linkedinId", res.body.linkedin);
          }
        } else {
          this.linkedinId = res.error.linkedin;
          localStorage.setItem("linkedinId", res.error.linkedin);
          this.showpopMsg = false;
          if (
            res.error.message ==
            "'userid' - ErrorDetail(string='linkedin credentials with this userid already exists.', code='unique')"
          ) {
            this.toastr.error(
              "Your LinkedIn profile is already connected to an existing Triggify account. Please login to your existing account.",
              "",
              {
                closeButton: true,
                extendedTimeOut: 0,
                timeOut: 0,
                tapToDismiss: false,
                positionClass: "toast-bottom-center",
              }
            );
          } else if (
            res.error.message ==
            "Incorrect Username or Password, Please check it"
          ) {
            this.toastr.error(
              "We were not able to connect your account. Please double check your credentials and try again.",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          } else if (
            res.error.message ==
            "previous login script is running, please try again after 5 minutes"
          ) {
            this.toastr.error(
              "previous login script is running, please try again after 5 minutes",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          } else if (res.error.message == "failed to login") {
            this.toastr.error("failed to login.", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          } else if (
            res.error.message.includes("ERR_TUNNEL_CONNECTION_FAILED")
          ) {
            this.toastr.error("Proxy Failed Please Try after sometime", "", {
              closeButton: true,
              positionClass: "toast-bottom-center",
            });
          } else {
            this.toastr.error(
              "Something Went wrong Please Try again or try after sometime",
              "",
              {
                closeButton: true,
                positionClass: "toast-bottom-center",
              }
            );
          }
          this.showorhideModal = false;
          this.linkedinConnectionStatus();
        }
      });
    } else {
      this.common
        .onUpdateToLinkedinId(bodyData, this.linkedinId)
        .subscribe((res) => {
          this.linkedinConnectionData = res.body;
          
          this.button_spinner = false;
          if (res.status == 200) {
            localStorage.setItem("linkedinId", this.linkedinId);
            if (res.body.message == "added") {
              this.toastr.success(res.body.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
              localStorage.setItem("linkedinId", this.linkedinId);
              // analytics.track("Connected LinkedIn in onboarding flow", {});
              // this.mixPanel.track("Connected LinkedIn successfully", {});
              this.linkedInProfileComponent.getLinkedinProfile(this.linkedinId);
              this.showpopMsg = false;
            } else if (
              res.body.message ==
              "Incorrect Username or Password, Please check it"
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "We were not able to connect your account. Please double-check your credentials and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes(
                "Failed to refresh IP or reached max retry limit"
              )
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Connection failed: LinkedIn is giving you a CAPTCHA because you logged in/out too many times. Please try again in 24 hours or use a different LinkedIn account.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes(
                "phone number is required, please add phone number on your account"
              )
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Please add a phone number to your LinkedIn account and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes("Your account is restricted")
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Your account is restricted. Please activate your LinkedIn account and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else if (
              res.body.message.includes("failed to generate captcha token")
            ) {
              this.showpopMsg = false;
              this.toastr.error(
                "Oops! We couldn't generate the captcha token. Please try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
              this.spinner.hide();
              this.showorhideModal = false;
            } else {
              this.showpopMsg = false;
              this.toastr.success(res.body.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
              this.spinner.hide();
              this.showorhideModal = true;
              localStorage.setItem("linkedinId", res.body.linkedin);
            }
          } else {
            this.showpopMsg = false;
            this.linkedinId = res.error.linkedin;
            localStorage.setItem("linkedinId", res.error.linkedin);
            if (
              res.error.message ==
              "'userid' - ErrorDetail(string='linkedin credentials with this userid already exists.', code='unique')"
            ) {
              this.toastr.error(
                "Your LinkedIn profile is already connected to an existing Triggify account. Please login to your existing account.",
                "",
                {
                  closeButton: true,
                  extendedTimeOut: 0,
                  timeOut: 0,
                  tapToDismiss: false,
                  positionClass: "toast-bottom-center",
                }
              );
            } else if (
              res.error.message ==
              "previous login script is running, please try again after 5 minutes"
            ) {
              this.toastr.error(
                "previous login script is running, please try again after 5 minutes",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
            } else if (
              res.error.message ==
              "Incorrect Username or Password, Please check it"
            ) {
              this.toastr.error(
                "We were not able to connect your account. Please double check your credentials and try again.",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
            } else if (res.error.message == "failed to login") {
              this.toastr.error("failed to login.", "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            } else if (
              res.error.message.includes("ERR_TUNNEL_CONNECTION_FAILED")
            ) {
              this.toastr.error("Proxy Failed Please Try after sometime", "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            } else {
              this.toastr.error(
                "Something Went wrong Please Try again or try after sometime",
                "",
                {
                  closeButton: true,
                  positionClass: "toast-bottom-center",
                }
              );
            }
            this.showorhideModal = false;
            this.linkedinConnectionStatus();
          }
        });
    }
  }

  verifyLinkedin() {
    this.button_spinner = true;
    this.showverificationpopMsg = true;
    // console.log(this.linkedInotpForm.value);
    // console.log(this.sub_type);
    delete this.linkedinConnectionData.message;
    // console.log(this.linkedinConnectionData, "connection data");
    this.linkedinConnectionData["pin"] = this.linkedInotpForm.value.otp;
    this.linkedinConnectionData["linkedin"] = this.linkedinId;
    // console.log(this.linkedinConnectionData, " afterconnection data");
    this.common
      .linkedinPinVerify(this.linkedinConnectionData)
      .subscribe((res) => {
        // console.log(res);
        this.button_spinner = false;
        if (res.status == 200) {
          this.showorhideModal = false;
          this.showverificationpopMsg = false;
          // this.mixPanel.track("Connected LinkedIn successfully", {});
          // analytics.track("Connected LinkedIn in onboarding flow", {});
          this.linkedInProfileComponent.getLinkedinProfile(this.linkedinId);
        } else {
          this.toastr.error(
            "We were not able to connect. You most likely have entered the wrong verification code. Please try again.",
            "",
            {
              closeButton: true,
              positionClass: "toast-bottom-center",
            }
          );
        }
      });
  }


  linkedinConnectionStatus() {
    this.common.getLinkedinProfile(this.linkedinId).subscribe((res) => {
      if (res.body.cookie == null) {
        this.linkedinStatus = false;
      } else {
        this.linkedinStatus = true;
      }
    });
  }


  planCreate() {
    if (this.headerHeading == "free") {
      this.router.navigate(["dashboard/view_all"]);
      localStorage.setItem("susbcribe", "True");
    } else {
      if (this.navigateToUrl == true) {
        this.router.navigate(["billing/subscription"]);
      } else {
        this.email = localStorage.getItem("email");
        this.id = localStorage.getItem("id");
        this.uuid = localStorage.getItem("uuid");
        this.name = localStorage.getItem("name");
        pendo.initialize({
          visitor: {
            id: this.uuid,
            email: this.email,
            full_name: this.name,
            role: "User",
          },
          account: {
            id: this.id,
          },
        });
        localStorage.setItem("susbcribe", "True");
        this.router.navigate(["account"]);
      }
    }
  }

  // yearly plan

  togglePlan(value: boolean) {
    this.yearlyPlan = value;
  }

  // agency Plan

  agencyPlanToggle() {
    if (this.agencyPlan == false) {
      this.btnTitle = "View normal plans";
      this.agencyPlan = true;
    } else {
      this.btnTitle = "View agency plans";
      this.agencyPlan = false;
    }
  }


  getInTouch() {
    window.open("https://www.triggify.io/", "_blank").focus();
  }

  // handle successfully seleted linkedin profile

  handleSuccess() {
    // Handle the success response here
    this.planCreate();
  }
}
